<!-- 添加学分制度 -->
<template>
    <div class="addScore">
        <div class="step1" v-show="currentStep=='step1'">
            <el-form ref="form" :model="form" label-width="80px" label-position="left">
                <el-form-item label="标题" prop="name">
                    <el-input v-model="form.name" :disabled='disabled'></el-input>
                </el-form-item>
                <!-- <el-form-item label="适用时间">
                    <el-date-picker v-model="form.value1" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item label="适用人员">
                    <el-checkbox-group v-model="form.persons">
                        <el-checkbox :disabled='disabled' :label="item.value" v-for="(item,index) in person"
                            :key="index">{{item.label}}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="适用角色">
                    <el-select v-model="form.role" placeholder="请选择角色" :disabled='disabled'>
                        <el-option :label="item" :value="key" v-for="(item,key) in roles" :key="key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="总成绩">
                    <el-radio-group v-model.number="form.resource">
                        <el-radio :label="0" :disabled='disabled'>总分</el-radio>
                        <el-radio :label="1" :disabled='disabled'>学分</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div class="scoreBox flex-align">
                    <el-input v-model.number="score" @input="setScore(score)" :disabled='disabled'></el-input>
                    <span>分</span>
                </div>
                <el-form-item>
                    <el-button type="primary" @click="next">下一步</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="step2" v-show="currentStep=='step2'">
            <div class="menu">
                <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
                    <el-radio-button label="async">异步课程</el-radio-button>
                    <el-radio-button label="sync">同步课程</el-radio-button>
                    <el-radio-button label="task">作业提交</el-radio-button>
                    <el-radio-button label="join">参与活动</el-radio-button>
                    <el-radio-button label="resource">资源应用</el-radio-button>
                </el-radio-group>
            </div>
            <!-- 异步课程 -->
            <el-form ref="form" v-show="tabPosition=='async'" :model="asyncForm" label-width="80px"
                label-position="left">
                <el-form-item label="总分值">
                    <div class="flex-align">
                        <el-input v-model.number="asyncForm.total" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
                <el-form-item label="评价标准">
                    <el-radio-group v-model="evaluateType" style="margin-bottom: 30px;" :disabled='disabled'>
                        <el-radio-button label="time">时长</el-radio-button>
                        <el-radio-button label="number">数量</el-radio-button>
                    </el-radio-group>
                    <div class="itemBox flex-align">
                        <el-form-item class="rule_item" :label="evaluateType=='time'?'观看时长':'观看个数'">
                            <div class="flex-align">
                                <div class="flex-align">
                                    <el-input v-model.number="asyncForm.detailRules[0].ruleNum" size="mini"
                                        :disabled='disabled'></el-input>
                                    {{evaluateType=='time'?'min':'个'}}
                                </div>
                                <div class="flex-align" style="margin-left:10px">
                                    <el-input v-model.number="asyncForm.detailRules[0].ruleValue" size="mini"
                                        :disabled='disabled'>
                                    </el-input>
                                    {{evaluateType=='time'?'分/学分':'学分'}}
                                </div>
                            </div>
                        </el-form-item>
                        <i class="el-icon-circle-plus-outline" @click="addItem"></i>
                    </div>
                    <el-form-item :label="evaluateType=='time'?'观看时长':'观看个数'"
                        v-for="(item,index) in asyncForm.detailRules.slice(1)" :key="index">
                        <div class="flex-align">
                            <div class="flex-align">
                                <el-input v-model.number="item.ruleNum" size="mini" :disabled='disabled'></el-input>
                                {{evaluateType=='time'?'min':'个'}}
                            </div>
                            <div class="flex-align" style="margin-left:10px">
                                <el-input v-model.number="item.ruleValue" size="mini" :disabled='disabled'></el-input>
                                {{evaluateType=='time'?'分/学分':'学分'}}
                            </div>
                        </div>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="达标分数">
                    <div class="flex-align">
                        <el-input v-model="asyncForm.achievement" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
            </el-form>
            <!-- 同步课程 -->
            <el-form ref="form" v-show="tabPosition=='sync'" :model="syncForm" label-width="80px" label-position="left">
                <el-form-item label="总分值:">
                    <div class="flex-align">
                        <el-input v-model="syncForm.total" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
                <div class="flex-align">
                    <el-form-item label="直播评价标准:" style="margin-right:30px">
                        <div class="flex-align">
                            <div class="complete">每完成</div>
                            <el-input v-model.number="syncForm.detailRules[0].ruleNum" size="mini"
                                style="margin-left:10px" :disabled='disabled'></el-input>个
                        </div>
                    </el-form-item>
                    <el-form-item label="分值:">
                        <div class="flex-align">
                            <el-input v-model.number="syncForm.detailRules[0].ruleValue" size="mini"
                                :disabled='disabled'></el-input>
                            <div class="complete">分/学分</div>
                        </div>
                    </el-form-item>
                </div>
                <div class="flex-align">
                    <el-form-item label="回放评价标准:" style="margin-right:30px">
                        <div class="flex-align">
                            <div class="complete">每完成</div>
                            <el-input v-model.number="syncForm.detailRules[1].ruleNum" size="mini"
                                style="margin-left:10px" :disabled='disabled'></el-input>个
                        </div>
                    </el-form-item>
                    <el-form-item label="分值:">
                        <div class="flex-align">
                            <el-input v-model.number="syncForm.detailRules[1].ruleValue" size="mini"
                                :disabled='disabled'></el-input>
                            <div class="complete">分/学分</div>
                        </div>
                    </el-form-item>
                </div>
                <el-form-item label="达标分数:">
                    <div class="flex-align">
                        <el-input v-model.number="syncForm.achievement" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
            </el-form>
            <!-- 作业提交 -->
            <el-form ref="form" v-show="tabPosition=='task'" :model="taskForm" label-width="80px" label-position="left">
                <el-form-item label="总分值:">
                    <div class="flex-align">
                        <el-input v-model.number="taskForm.total" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
                <div class="flex-align">
                    <el-form-item label="评价标准:" style="margin-right:30px">
                        <div class="flex-align">
                            <span>每完成</span>
                            <el-input v-model.number="taskForm.detailRules[0].ruleNum" size="mini"
                                style="margin-left:10px" :disabled='disabled'></el-input>个
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div class="flex-align">
                            分值:
                            <el-input v-model.number="taskForm.detailRules[0].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'></el-input>分/学分
                        </div>
                    </el-form-item>
                </div>
                <el-form-item label="达标分数:">
                    <div class="flex-align">
                        <el-input v-model.number="taskForm.achievement" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
            </el-form>
            <!-- 参与活动 -->
            <el-form ref="form" v-show="tabPosition=='join'" :model="joinForm" label-width="80px" label-position="left">
                <!-- <el-form-item label="适用角色">
                    <el-select v-model="joinForm.class" placeholder="请选择活动分类">
                        <el-option label="分类一" value="shanghai"></el-option>
                        <el-option label="分类二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="适用角色">
                    <el-select v-model="joinForm.style" placeholder="请选择活动形式">
                        <el-option label="形式一" value="shanghai"></el-option>
                        <el-option label="形式二" value="beijing"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="总分值:">
                    <div class="flex-align">
                        <el-input v-model.number="joinForm.total" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
                <div class="flex-align">
                    <el-form-item label="评价标准:" style="margin-right:30px">
                        <div class="flex-align">
                            <span>参与活动</span>
                            <el-input v-model.number="joinForm.detailRules[0].ruleNum" size="mini"
                                style="margin-left:10px" :disabled='disabled'></el-input>
                            个
                        </div>
                        <div class="flex-align">
                            <span>创建活动</span>
                            <el-input v-model.number="joinForm.detailRules[1].ruleNum" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            个
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div class="flex-align">
                            分值:
                            <el-input v-model.number="joinForm.detailRules[0].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>分/学分
                        </div>
                        <div class="flex-align">
                            分值:
                            <el-input v-model.number="joinForm.detailRules[1].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            分/学分
                        </div>
                    </el-form-item>
                </div>
                <el-form-item label="达标分数:">
                    <div class="flex-align">
                        <el-input v-model.number="joinForm.achievement" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
            </el-form>
            <!-- 资源应用 -->
            <el-form ref="form" v-show="tabPosition=='resource'" :model="resourceForm" label-width="80px"
                label-position="left">
                <el-form-item label="总分值:">
                    <div class="flex-align">
                        <el-input v-model.number="resourceForm.total" size="mini" :disabled='disabled'></el-input>分
                    </div>
                </el-form-item>
                <div class="flex-align">
                    <el-form-item label="评价标准:" style="margin-right:30px">
                        <div class="flex-align">
                            <span>上传</span>
                            <el-input v-model.number="resourceForm.detailRules[0].ruleNum" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            个
                        </div>
                        <div class="flex-align">
                            下载<el-input v-model.number="resourceForm.detailRules[1].ruleNum" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            个
                        </div>
                        <div class="flex-align">
                            分享<el-input v-model.number="resourceForm.detailRules[2].ruleNum" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            个
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div class="flex-align">
                            分值
                            <el-input v-model.number="resourceForm.detailRules[0].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            分/学分
                        </div>
                        <div class="flex-align">
                            分值
                            <el-input v-model.number="resourceForm.detailRules[1].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            分/学分
                        </div>
                        <div class="flex-align">
                            分值
                            <el-input v-model.number="resourceForm.detailRules[2].ruleValue" size="mini"
                                style="margin-left:10px" :disabled='disabled'>
                            </el-input>
                            分/学分
                        </div>
                    </el-form-item>
                </div>
                <el-form-item label="达标分数:">
                    <div class="flex-align">
                        <el-input v-model.number="resourceForm.achievement" size="mini" :disabled='disabled'></el-input>
                        分
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div class="btn" v-show="currentStep=='step2'">
            <el-button type="primary" @click="prev">上一步</el-button>
            <el-button type="primary" @click="save">{{disabled?'返回':'保存'}}</el-button>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                /**
                 *  * 索引, 是否涉及排序!?  类型 0 同步课 , 1 异步课 ,3 作业提交 ,4 参与活动 , 5 资源应用
                 * 根据评分维度类型的不同 分别代表不同的规则
                 * 类型 : 同步课:  0-50 直播  51-99回放
                 *  异步课 完成,不同的 时间长度/个数 序号  100-150  为计时   151-199 为计数,
                 *  作业提交 300,
                 *  参与活动  400 参与活动 401 创建活动,
                 *  资源应用 500 上传 501 下载 502 分享
                 */
                disabled: false,
                score: null,
                form: {
                    name: '',
                    persons: [],
                    resource: 0,
                    value1: '',
                    role: ''
                },
                asyncForm: {
                    achievement: '', //达标分数
                    detailRules: [{
                        ruleIndex: 101, // 100-150  为计时   151-199 为计数,
                        ruleNum: '', // 观看时长||观看个数
                        ruleValue: '' // 分||学分
                    }],
                    total: '', //总分值
                    type: 1 // 类型
                },
                syncForm: {
                    achievement: '',
                    detailRules: [{
                        ruleIndex: 0, // 0-50 直播
                        ruleNum: '',
                        ruleValue: ''
                    }, {
                        ruleIndex: 51, // 51-99回放
                        ruleNum: '',
                        ruleValue: ''
                    }],
                    total: '',
                    type: 0
                },
                taskForm: {
                    achievement: '',
                    detailRules: [{
                        ruleIndex: 300, // 300 作业提交
                        ruleNum: '',
                        ruleValue: ''
                    }],
                    total: '',
                    type: 3
                },
                joinForm: {
                    achievement: '',
                    detailRules: [{
                        ruleIndex: 400, // 400 参与活动
                        ruleNum: '',
                        ruleValue: ''
                    }, {
                        ruleIndex: 401, // 401 创建活动
                        ruleNum: '',
                        ruleValue: ''
                    }],
                    total: '',
                    type: 4
                },
                resourceForm: {
                    achievement: '',
                    detailRules: [{
                        ruleIndex: 500, // 500 上传
                        ruleNum: '',
                        ruleValue: ''
                    }, {
                        ruleIndex: 501, // 501 下载
                        ruleNum: '',
                        ruleValue: ''
                    }, {
                        ruleIndex: 502, // 502 分享
                        ruleNum: '',
                        ruleValue: ''
                    }],
                    total: '',
                    type: 5
                },
                tabPosition: 'async',
                currentStep: 'step1',
                evaluateType: 'time',
                person: [{
                        label: '全部',
                        value: 0
                    },
                    {
                        label: '参训教师',
                        value: 100
                    }, {
                        label: '学生',
                        value: 121
                    }, {
                        label: '师范生',
                        value: 193
                    }
                ],
                roles: {},
                rules: {
                    totalScore: [{
                        required: true,
                        type: 'number',
                        message: '请输入总分数',
                        trigger: 'blur'
                    }]
                }
            }
        },
        watch: {
            evaluateType(newVal) {
                console.log('评价标准发生改变:', newVal);
                if (newVal == 'time') {
                    this.asyncForm.detailRules = [{
                        ruleIndex: 101, // 100-150  为计时   151-199 为计数,
                        ruleNum: '', // 观看时长||观看个数
                        ruleValue: '' // 分||学分
                    }]
                } else {
                    this.asyncForm.detailRules = [{
                        ruleIndex: 151, // 100-150  为计时   151-199 为计数,
                        ruleNum: '', // 观看时长||观看个数
                        ruleValue: '' // 分||学分
                    }]
                }

            }
        },
        methods: {
            next() {
                if (!this.form.name) {
                    this.$message({
                        message: '请输入标题',
                        type: 'warning'
                    });
                    return false
                };
                if (this.form.persons.length == 0) {
                    this.$message({
                        message: '请选择适用人员',
                        type: 'warning'
                    });
                    return false
                };
                if (!this.form.role) {
                    this.$message({
                        message: '请选择适用角色',
                        type: 'warning'
                    });
                    return false
                };
                if (!this.score) {
                    this.$message({
                        message: '请输入总分/学分',
                        type: 'warning'
                    });
                    return false
                }
                this.currentStep = 'step2';
            },
            prev() {
                this.currentStep = "step1"
            },
            setScore(score) {
                if (!/^\d+(\.\d+)?$/.test(score)) {
                    this.$message({
                        message: '请输入正确的分数/学分',
                        type: 'warning'
                    });
                    this.score = '';
                    return false
                }
            },
            save() {
                if (this.disabled) {
                    this.$router.go(-1)
                } else {
                    let arr = [this.asyncForm, this.syncForm, this.taskForm, this.joinForm, this.resourceForm];
                    let data = {
                        projectId: this.$store.state.user.currentProject.id,
                        type: this.form.resource, //成绩计分方式 0-总分制 1-学分制
                        title: this.form.name, //标题
                        roles:this.form.persons.includes(0)?0:this.form.persons.join(), //适用人员(角色)
                        position: this.form.role, //适用角色(职称)
                        value: this.score, //分值
                        content: JSON.stringify(arr), //规则细则
                        id: this.$route.query.id ? this.$route.query.id : ''
                    };
                    console.log('参数:', data)
                    if (this.$route.query.type == 'edit') {
                        this.$Api.Score.editScore(data)
                            .then(res => {
                                console.log('添加成功:', res);
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                            })
                            .catch(err => {
                                console.log('添加失败:', err);
                                this.$message.error('保存失败,请联系管理员');
                            })
                    } else {
                        this.$Api.Score.addScore(data)
                            .then(res => {
                                console.log('添加成功:', res);
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                            })
                            .catch(err => {
                                console.log('添加失败:', err);
                                this.$message.error('保存失败,请联系管理员');
                            })
                    }

                }

            },
            addItem() {
                // 遍历数组，找到最大的type值
                let maxType = this.asyncForm.detailRules.reduce((prev, curr) => {
                    return Math.max(prev, curr.ruleIndex);
                }, 0);
                this.asyncForm.detailRules.push({
                    ruleNum: '',
                    ruleValue: '',
                    ruleIndex: maxType + 1,
                    // key: Date.now()
                });
            },
            getRoles() {
                let data = {
                    projectId: this.$store.state.user.currentProject.id,
                }
                this.$Api.Score.getRoles(data)
                    .then(res => {
                        // console.log('角色列表:', res);
                        this.roles = res.data.titleMap
                    })
                    .catch(err => {
                        console.log('获取角色失败:', err)
                    })
            },
            // 查看
            check() {
                let data = {
                    id: this.$route.query.id
                }
                this.$Api.Score.viewScore(data)
                    .then(res => {
                        console.log('查看结果:', res);
                        this.form.name = res.data.title;
                        this.form.persons = res.data.roles.split(',');
                        this.form.role = res.data.position.toString();
                        this.form.resource = res.data.type;
                        this.score = res.data.value;
                        let content = JSON.parse(res.data.content);
                        this.asyncForm = content[0];
                        this.syncForm = content[1];
                        this.taskForm = content[2];
                        this.joinForm = content[3];
                        this.resourceForm = content[4];
                    })
                    .catch(err => {
                        console.log('查看失败:', err)
                    })
            }
        },
        mounted() {
            this.getRoles();
            if (this.$route.query.type == 'view') {
                this.disabled = true;
                this.check()
            } else if (this.$route.query.type == 'edit') {
                this.check()
            }
        },
    };
</script>
<style lang='less' scoped>
    .addScore {
        margin-bottom: 100px;
        background-color: #ffffff;
        box-shadow: 0px 2px 10px 0px #f5f8fa;
        border-radius: 8px;
        padding: 0 60px;

        .step1 {
            width: 900px;
            margin: 30px auto;

            /deep/ .el-input__inner {
                width: 300px;
            }

            .scoreBox {
                margin-left: 80px;
                width: 100px;
                margin-right: 10px;
                margin-bottom: 20px;

                span {
                    display: inline-block;
                    margin-left: 10px;
                }

                /deep/ .el-input__inner {
                    width: 100%;
                }
            }
        }

        .step2 {
            width: 900px;
            margin: 30px auto;
            min-height: 400px;
            .el-input {
                width: 100px;
                margin-right: 10px;
            }


            .menu {
                margin: 0 auto;
            }

            .itemBox {
                .rule_item {
                    display: flex;

                    /deep/ .el-form-item__content {
                        margin-left: 0 !important
                    }
                }

                i {
                    cursor: pointer;
                    margin-left: 10px;
                    font-size: 26px;
                    color: #409EFF;
                }
            }

            /deep/ .el-form-item__label {
                width: 100px !important // flex-shrink: 0;
            }

            /deep/ .el-form-item__content {
                margin-left: 100px !important;
            }



            .complete {
                flex-shrink: 0;
            }
        }
        .btn{
            margin-bottom: 20px;
            text-align: center;
        }
    }
</style>